import GoogleButton from 'react-google-button'
import React, {Component} from 'react';
import './CLogin.css';
import ApiV0 from '../services/ApiV0';
import CUtils from '../services/CUtils';
import {CConstants} from '../common/CConstants';
import {analyticsEvent, analyticsPageView} from '../core/AnalyticsManager';
import {Alert} from 'react-bootstrap';
import {IoIosPersonAdd} from "react-icons/io";
import {FiEye, FiEyeOff} from 'react-icons/fi';
import CBreadcrumb from '../common/CBreadcrumb';
import CLoadInProgress from '../common/CLoadInProgress';

import {withTranslation} from 'react-i18next';
import {Cookies, withCookies} from "react-cookie";
import {instanceOf} from "prop-types";
import {hasConsent, setCookies} from "../services/CookiesUtils";

class CLogin extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired,
    };

    constructor(props) {
        super(props);
        setCookies(props.cookies);
        this.state = {
            waiting: false,
            errorMessage: null,
            isRevealPwd: false
        }
    }

    componentDidMount() {
        if (!hasConsent()) {
            document.location.href = "/cookies";
            return;
        }
        const {t} = this.props;
        this._refocus();
        document.title = t('login.title');
        analyticsPageView('login');
    }

    _refocus() {
        this.loginInput.focus()
    }

    google() {
        const evt = "login Google";
        analyticsEvent(CConstants.GG_CATEGORY.AUTH, evt, evt);
        this.setState({
            waiting: true
        }, async function () {
            window.location.href = ApiV0.googleSsoUrl();
        })
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({
                waiting: true
            }, () => this.doLogin(this.state.login, this.state.password)
        );
    }

    doLogin(login, password) {
        const {t} = this.props;
        ApiV0.login({login, password})
            .then((loginSuccessData) => {
                const evt = "login OK";
                analyticsEvent(CConstants.GG_CATEGORY.AUTH, evt, evt, login);
                window.location.href = (true === loginSuccessData.firstLogin) ? '/me' :
                    loginSuccessData.returnTo ? loginSuccessData.returnTo :
                        '/';
            })
            .catch(apiError => {
                const evt = "login FAILED";
                analyticsEvent(CConstants.GG_CATEGORY.AUTH, evt, evt, login);
                const errorMessage = (apiError && "UNAUTHORIZED" === apiError.code) ? t('login.error.loginFailed') :
                    CUtils.userErrorOf("login", t, apiError);
                this.setState({errorMessage, waiting: false}, () => this._refocus());
            });
    }

    toggleEye() {
        this.setState({isRevealPwd: !this.state.isRevealPwd});
    }

    render() {
        if (this.state.waiting === true) {
            return (<CLoadInProgress/>)
        }
        const {t} = this.props;
        const submitDisabled = !(this.state.login && this.state.password);
        const isRevealPwd = this.state.isRevealPwd;
        const alert = this.state.errorMessage ?
            (<div className="loginError"><Alert variant="warning">
                {this.state.errorMessage}
            </Alert></div>)
            : null;
        const breadcrumb = (<CBreadcrumb activeLabel={t('breadcrumb.login')}/>);
        return (
            <div className="CLogin">
                {breadcrumb}
                <div className="alignCenter">
                    {alert}
                    <form onSubmit={this.handleSubmit.bind(this)} autoComplete="off">
                        <div className="card">
                            <div className="card-body">

                                <div className="form-group">
                                    <label>{t('login.form.username.label')}</label>
                                    <input name="login"
                                           type="text"
                                           className="form-control"
                                           placeholder={t('login.form.username.placeholder')}
                                           pattern={CConstants.LOGIN_REGEX}
                                           maxLength={CConstants.LOGIN_MAX_LENGTH}
                                           onChange={this.handleInputChange.bind(this)}
                                           title=''
                                           onInput={e => e.target.setCustomValidity('')}
                                           onInvalid={e => e.target.setCustomValidity(t('common.loginCustomValidity'))}
                                           ref={(input) => {
                                               this.loginInput = input;
                                           }}
                                    />
                                </div>

                                <div className="form-group">
                                    <label>{t('login.form.password.label')}</label>
                                    <input name="password"
                                           type={isRevealPwd ? "text" : "password"}
                                           className="form-control"
                                           placeholder={t('login.form.password.placeholder')}
                                           maxLength={CConstants.PASSWORD_MAX_LENGTH}
                                           title=''
                                           onChange={this.handleInputChange.bind(this)}
                                           autoComplete="off"/>
                                    <i className="eyeFloatRight"
                                       onClick={this.toggleEye.bind(this)}
                                       title={isRevealPwd ? t('login.form.password.hide') : t('login.form.password.show')}
                                    >{isRevealPwd ? (<FiEyeOff/>) : (<FiEye/>)}</i>
                                </div>

                                {/*  Remember me
              <div className="form-group">
                  <div className="custom-control custom-checkbox">
                      <input type="checkbox" className="custom-control-input" id="customCheck1" />
                      <label className="custom-control-label" htmlFor="customCheck1">Remember me</label>
                  </div>
              </div>
  */}
                                <button type="submit" className="btn btn-secondary btn-login"
                                        disabled={submitDisabled}>{t('login.form.submit')}</button>
                                <br/>

                                <div className="subscribe">{t('login.form.noAccount')}
                                    <a href="/register" className="txt"><IoIosPersonAdd/> {t('login.form.register')}</a>
                                </div>
                                <div className="forgot-password">
                                    {t('login.form.forgot')} <a href="/remind"
                                                                className="txt">{t('login.form.remind')}</a>
                                </div>

                                <hr/>

                                <p>{t('login.form.googleUser')}</p>
                                <GoogleButton
                                    onClick={this.google.bind(this)}
                                    type="light"
                                    label={t('login.form.googleLogin')}
                                    className="googleButtonAppend"
                                />

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default withCookies(withTranslation('common')(CLogin));