import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';
import Switch from "react-switch"; // https://www.npmjs.com/package/react-switch
import './CCookiesModal.css';
import {CConstants} from '../common/CConstants';
import {analyticsEvent} from './AnalyticsManager';
import {withTranslation} from 'react-i18next';
import {
    COOKIE_EXPIRATION_DEFAULT_DAYS,
    hasConsent,
    hasConsentStats,
    setConsent,
    setConsentStats,
    setCookies
} from "../services/CookiesUtils";
import {Cookies, withCookies} from "react-cookie";
import {instanceOf} from "prop-types";

class CCookiesModal extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired,
    };

    constructor(props) {
        super(props);
        setCookies(props.cookies);
        this.state = {
            consentCookies: hasConsent(),
            consentStatsCookies: hasConsentStats(),
            modalShow: true,
            formConsentStatsCookies: hasConsentStats(true),
        }
    }

    onTerminate() {
        setConsent(true);// mandatory cookies
        setConsentStats(this.state.formConsentStatsCookies);
        const evt = `cookies validate - stats=${this.state.formConsentStatsCookies}`;
        analyticsEvent(CConstants.GG_CATEGORY.LEGAL, evt, evt);
        this.setState({modalShow: false});
    }

    componentDidMount() {
    }

    handleConsentStatsChange(formConsentStatsCookies) {
        // DEBUG // console.log("handleConsentStatsChange", formConsentStatsCookies)
        this.setState({formConsentStatsCookies});
    }

    render() {
        const {t} = this.props;
        const modalShow = this.state.modalShow;
        // const basicState = formConsentCookies ? "accepter" : "refuser";
        // const basicCurrentState = this.state.consentCookies ? "actif" : "inactif";
        const formConsentStatsCookies = this.state.formConsentStatsCookies;
        const statsState = formConsentStatsCookies ? t('cookies.accept') : t('cookies.refuse');
        const statsCurrentState = this.state.consentStatsCookies ? t('cookies.active') : t('cookies.inactive');
        const cookiesRetentionNotice = t('cookies.retention') + ' '
            + COOKIE_EXPIRATION_DEFAULT_DAYS + t('cookies.expiration_day', {'count': COOKIE_EXPIRATION_DEFAULT_DAYS});

        const modalContent = (<>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">{t('common.cookiesTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t('cookies.browser')}
                &#160;(<a target="_new" className="txt"
                          href="https://www.cnil.fr/fr/definition/cookie">{t('cookies.moreDetails')}</a>).
                <br/><br/>
                <h4>{t('cookies.mandatoryInfo')} {/*<sup><small>{basicCurrentState}</small></sup>*/}</h4>
                <div>
                    {t('cookies.mandatoryInfoP')}<br/>
                    &#160;
                </div>
                <h4>{t('cookies.analyticsInfo')} <sup><small>{statsCurrentState}</small></sup></h4>
                <div>
                    {t('cookies.analyticsInfoP')}
                    <div className="alignRight">
                        <div className="switchState">{statsState}</div>
                        <Switch onChange={(val) => this.handleConsentStatsChange(val)}
                                checked={formConsentStatsCookies}/>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <>{cookiesRetentionNotice}</>
                <Button variant="info" onClick={this.onTerminate.bind(this)}>{t('cookies.submit')}</Button>
            </Modal.Footer>
        </>);

        return (<>
            <Modal
                show={modalShow}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
                className="smallScreen"
            >
                {modalContent}
            </Modal>
            <Modal
                show={modalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdrop="static"
                keyboard={false}
                className="largeScreen"
            >
                {modalContent}
            </Modal>
        </>);
    }
}

export default withCookies(withTranslation('common')(CCookiesModal));