import React, {Component} from 'react';
import {Alert} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';
import {DiCode} from 'react-icons/di';

import './CHome.css';
import {analyticsEvent, analyticsPageView} from '../core/AnalyticsManager';
import CBreadcrumb from '../common/CBreadcrumb';
import CLoadInProgress from '../common/CLoadInProgress';
import CListingChoice from '../listings/CListingChoice';
import ApiV0 from '../services/ApiV0';
import {CConstants} from '../common/CConstants';

const HOME_TOTAL_PRODUCTS_COUNT = 9;

const currentPath = window.location.pathname;
// AUTO SCROLL
let scrollDebounce = false; // true;
let initialListing = false;

export default withTranslation('common')(class CHome extends Component {

    constructor(props) {
        super(props);
        this.state = {
            errorMessage: null,
            limit: HOME_TOTAL_PRODUCTS_COUNT,
            bookmark: null,
            listings: null
        };
    }

    componentDidMount() {
        const {t} = this.props;
        // DEBUG // console.log("CHome::componentDidMount", this.state.listings)
        document.title = t('home.title');
        analyticsPageView('home - ' + currentPath);
        document.addEventListener("scroll", this.onScroll);
        if (initialListing === false) {
            this.doSearchListings();
            initialListing = true;
        }
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.onScroll);
    }

    hasReachedBottom(margin) {
        return ((window.innerHeight + window.scrollY + margin) >= document.body.offsetHeight);
    }

    onScroll = () => {
        if (!this.hasReachedBottom(100)) {
            return;
        }
        if (scrollDebounce) {
            scrollDebounce = false;
            this.onNextListings(window.scrollY);
            setTimeout(function () {
                scrollDebounce = true;
            }, 500);
        }
    }

    doSearchListings() {
        const {t} = this.props;
        const bookmark = this.state.bookmark;
        const filter = {mode: "featured", limit: this.state.limit};
        if (bookmark !== null && bookmark !== undefined) {
            filter.bookmark = bookmark;
        }
        const evt = "home getListings";
        analyticsEvent(CConstants.GG_CATEGORY.LISTING, evt, evt, 0);
        // DEBUG // console.info("searchListings", JSON.stringify(filter), bookmark);
        ApiV0.getListings(filter)
            .then((listingsResults) => {
                if (listingsResults.length) {
                    const newListing = this.state.listings === null ? listingsResults : this.state.listings.concat(listingsResults);
                    // DEBUG // console.info("searchListings newListing " + JSON.stringify(newListing));
                    this.setState({
                        listings: newListing,
                        errorMessage: null,
                        infoMessage: null,
                        hasNext: true,
                        searchTodo: false
                    });
                } else {
                    const newState = {hasNext: false};
                    if (bookmark == null || bookmark === '') {
                        newState.listings = [];
                    }
                    this.setState(newState)
                }
            })
            .catch((getErrorMessage) => {
                console.error(getErrorMessage);
                this.setState({errorMessage: `${t('home.searchError')} ${getErrorMessage}`, searchTodo: false});
            });
    }

    onNextListings() {
        const cHome = this;
        if (this.state.searchTodo === false && this.state.listings && this.state.listings.length > 0) {
            const evt = this.state.section ? this.getSectionName() + " next" : "section next";
            analyticsEvent(CConstants.GG_CATEGORY.LISTING, evt, evt, this.state.listings.length);
            const lastCreation_tsz = this.state.listings[this.state.listings.length - 1].creation_tsz;
            this.setState({bookmark: lastCreation_tsz}, () => cHome.doSearchListings());
        }
    }

    render() {
        const lang = ApiV0.getLang();
        const {t} = this.props;
        const title = t('ch.home');
        const homeMessageRaw = window.isSet(this.props.me?.homeMessage) && window.isSet(this.props.me?.homeMessage[lang]) ? this.props.me?.homeMessage[lang] : null;
        let infoPlace = homeMessageRaw ? (<div className="homeMsg">{homeMessageRaw}</div>) : null;
        const errorPlace = this.state.errorMessage ? (
            <Alert variant="warning">{this.state.errorMessage}</Alert>) : null;
        if (this.state.wip === true || this.state.listings === null) {
            return this.state.errorMessage ? errorPlace : (<CLoadInProgress/>);
        }
        const breadcrumb = (<CBreadcrumb withHome={false} activeDragon={true} activeLabel={title}/>);
        const {listings} = this.state;
        return (
            <div id="listingsList" className="chome"
                 itemScope
                 itemType="https://schema.org/ItemList"
                 ref={(input) => {
                     this.chome = input;
                 }}>
                {infoPlace}
                <span className="hidden" itemProp="name">{title}</span>
                {breadcrumb}
                <div id="listingsInnerList" className="list-inner" onScroll={this.onScroll.bind(this)}
                     ref={this.listInnerRef}>
                    {errorPlace}
                    <div className="listItems alignCenter">
                        {listings
                            .filter(p => p.images && p.images.length > 0)
                            .map(product => {
                                return (
                                    <CListingChoice key={product.listing_id}
                                                    index={product.listing_id}
                                                    section={this.props.sectionId}
                                                    product={product}
                                    />)
                            })}
                    </div>
                </div>
                <div className="endOfList"><DiCode/></div>
            </div>
        );
    }
})