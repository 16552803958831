const DAY_IN_SEC = 24 * 60 * 60;
const DEBUG_COOKIES = false;

export const COOKIE_LANG_KEY = 'site-lang';
export const COOKIE_CONSENT = 'consentCookies';
export const COOKIE_CONSENT_STATS = 'consentStatsCookies';
export const COOKIE_EXPIRATION_DEFAULT_DAYS = 60;
export const isTrue = val => val === true || val === "true";

const COOKIE_DEFAULT_SET_OPTIONS = {path: '/', maxAge: COOKIE_EXPIRATION_DEFAULT_DAYS * DAY_IN_SEC};
const COOKIE_ONE_DAY_SET_OPTIONS = {path: '/', maxAge: DAY_IN_SEC};
const COOKIE_DEFAULT_REMOVE_OPTIONS = {path: '/'};

//~ singleton
class CookiesSingletonContainer {
}

CookiesSingletonContainer.instance = null;
export const getCookies = () => {
    return CookiesSingletonContainer.instance;
}
export const setCookies = cookies => {
    CookiesSingletonContainer.instance = cookies;
}

//~ get set common
export const getCookieValue = cookieName => getCookies().get(cookieName);
export const getCookieValueOrElse = (cookieName, elseValue) => {
    const value = getCookies().get(cookieName);
    return value !== undefined ? value : elseValue;
}
export const setDayCookieValue = (cookieName, cookieValue) => {
    getCookies().set(cookieName, cookieValue, COOKIE_ONE_DAY_SET_OPTIONS);
}
export const setCookieValue = (cookieName, cookieValue) => {
    getCookies().set(cookieName, cookieValue, COOKIE_DEFAULT_SET_OPTIONS);
}
export const removeCookie = cookieName => {
    getCookies().remove(cookieName, COOKIE_DEFAULT_REMOVE_OPTIONS)
}
export const removeCookies = cookiesNames => {
    cookiesNames.forEach(name => removeCookie(name));
}

//~ cookies modal
export const hasConsentStats = (defaultValue = true) => {
    const currentValue = getCookieValueOrElse(COOKIE_CONSENT_STATS, defaultValue)
    return isTrue(currentValue === undefined ? defaultValue : currentValue);
}
export const hasConsent = () => {
    const value = getCookies().get(COOKIE_CONSENT);
    DEBUG_COOKIES && console.log(`consent:=${value} type:${typeof value}`);
    return isTrue(value);
}
export const setConsent = (setValue = false) => {
    DEBUG_COOKIES && console.log(`setConsent(${setValue}) using options ${JSON.stringify(COOKIE_DEFAULT_SET_OPTIONS)}`)
    isTrue(setValue) ?
        getCookies().set(COOKIE_CONSENT, true, COOKIE_DEFAULT_SET_OPTIONS) :
        getCookies().remove(COOKIE_CONSENT);
}
export const setConsentStats = (setValue = false) => {
    DEBUG_COOKIES && console.log(`setConsentStats(${setValue}) using options ${JSON.stringify(COOKIE_DEFAULT_SET_OPTIONS)}`)
    isTrue(setValue) ?
        getCookies().set(COOKIE_CONSENT_STATS, true, COOKIE_DEFAULT_SET_OPTIONS) :
        getCookies().set(COOKIE_CONSENT_STATS, false, COOKIE_DEFAULT_SET_OPTIONS);
}